import { Component, OnInit } from '@angular/core';
import { SpxOAuthService } from '@seepex/ng-oauth';
import { IAMService, IIdentity } from 'app/auth';
import { environment } from 'environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  /**
   * Logout url
   */
  static LOGOUT_URL = `${environment.authorization.authorizationUri}/logout`;

  /**
   * Reset url
   */
  static RESET_PASSWORD_URL = `${environment.authorization.authorizationUri}/password/change`;

  // claims
  private allowToChangeUserPasswordClaim = 'SPS_AllowToChangeUserPassword';

  // template variables
  public identity: Observable<IIdentity>;
  public allowedToChangeUserPassword: Observable<boolean>;

  constructor(private oAuthService: SpxOAuthService, private iamService: IAMService) {}

  ngOnInit(): void {
    this.allowedToChangeUserPassword = this.iamService.ensureHasClaim(this.allowToChangeUserPasswordClaim);
  }

  /**
   * Logs the user out
   */
  public logout(): void {
    this.oAuthService.logOut();
  }

  /**
   * Redirects to the change password url
   */
  public changePassword(): void {
    window.location.href = `${HeaderComponent.RESET_PASSWORD_URL}?token=${this.oAuthService.getAccessToken()}`;
  }
}
