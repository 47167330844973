import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import cssVars from 'css-vars-ponyfill';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// replace css variables for internet explorer
cssVars({
  watch: true,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
